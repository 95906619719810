import React from 'react';
import styled from 'styled-components';
import { gv, ns } from '../../utils';

const Number = styled.span`
	flex: 0 0 ${props => ns(props.theme.controlHeightBase)};
	width: ${props => ns(props.theme.controlHeightBase)};
	height: ${props => ns(props.theme.controlHeightBase)};
	line-height: ${props => ns(props.theme.controlHeightBase)};
	text-align: center;
	font-family: ${props => props.theme.fontFamilyLogo};
	color: ${gv('secondaryAccentColor')};
	border-right: 2px solid ${gv('secondaryAccentColor')};
	font-size: ${props => ns(props.theme.fz.large2)};
	font-weight: bold;
	margin: 0;
`;

const Text = styled.div`
	&&& {
		margin: 0;
		flex: 0 0 calc(100% - ${props => ns(props.theme.controlHeightBase)});
		width: calc(100% - ${props => ns(props.theme.controlHeightBase)});
		padding: 0 0 0 ${props => ns(props.theme.gutter)};
	}
`;

export const NumContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	margin: 1.5em 0;
	& + & {
		margin-top: -0.5em;
	}
`;

export type NumProps = {
	num: number;
	children: string;
};
export default function Num(props: NumProps) {
	const { children, num } = props;
	return (
		<NumContainer>
			<Number>{num}</Number>
			<Text as={typeof children === 'string' ? 'p' : 'div'}>{children}</Text>
		</NumContainer>
	);
}
