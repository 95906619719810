import { graphql, Link, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useMemo, useState } from 'react';
import { Constrain, GeneralBody } from '../components/Containers';
import Cta from '../components/Cta';
import GeneralHero from '../components/GeneralHero';
import Seo from '../components/Seo';
import styled from 'styled-components';
import {
	Column,
	Columns,
	MainHeading,
	SidebarHeading,
} from '../components/SiteGrid';
import { Select, Text } from '../components/Controls';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import fuzzysearch from 'fuzzysearch';
import { Description } from '../components/Typography';
import FooterCredit from '../components/FooterCredit';
import AvailableInTags from '../components/AvailableInTags';
import Card from '../components/Card';
import { bp } from '../utils';
import { parseStyleData } from '@wpeform/react';
import Modal from '../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import Button from '../components/Button';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import EmbedForm from '../components/EmbedForm';

const ImageContainer = styled.div`
	.gatsby-image-wrapper {
		display: block !important;
		margin: 0 auto;
		max-width: 250px;
		@media ${props => bp(props, 'desktop')} {
			max-width: 400px;
		}
	}
`;

type ElementDef = {
	type: string;
	title: string;
};

declare namespace Intl {
	class ListFormat {
		constructor(...args: any[]);
		public format: (items: [string?]) => string;
	}
}

let formatter: any;
try {
	formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
} catch (e) {
	formatter = {
		format(items: string[]) {
			return items.join(', ');
		},
	};
}

const formCategories: {
	[key in keyof GatsbyTypes.AllExamplesQuery['exampleForms']]: string;
} = {
	businessForms: 'Business & Operations Forms',
	contactForms: 'Contact Forms',
	educationForms: 'Education Forms',
	eventsForm: 'Events & Booking Form',
	featuresDemo: 'Features Demonstration',
	paymentForms: 'Payment Estimators',
	quizForms: 'Quiz Forms',
	surveyForms: 'Survey Forms',
};
const formCategoriesOrder: (keyof GatsbyTypes.AllExamplesQuery['exampleForms'])[] =
	[
		'paymentForms',
		'businessForms',
		'eventsForm',
		'quizForms',
		'surveyForms',
		'contactForms',
		'educationForms',
		'featuresDemo',
	];

export default function Example(
	props: PageProps<GatsbyTypes.AllExamplesQuery>
) {
	const {
		data: { formMeta, exampleForms },
	} = props;

	// sort form meta by element category
	const sortedElements = useMemo(() => {
		const choices: ElementDef[] = [];
		const inputs: ElementDef[] = [];
		const designs: ElementDef[] = [];
		const securities: ElementDef[] = [];
		formMeta.formMeta?.elements.forEach(elm => {
			if (elm.definition.category === 'CHOICE') {
				choices.push({ type: elm.type, title: elm.definition.title });
			}
			if (elm.definition.category === 'DESIGN') {
				designs.push({ type: elm.type, title: elm.definition.title });
			}
			if (elm.definition.category === 'INPUT') {
				inputs.push({ type: elm.type, title: elm.definition.title });
			}
			if (elm.definition.category === 'SECURITY') {
				securities.push({ type: elm.type, title: elm.definition.title });
			}
		});
		return { choices, inputs, designs, securities };
	}, [formMeta]);

	const [formCategory, setFormCategory] = useState<
		keyof GatsbyTypes.AllExamplesQuery['exampleForms'] | 'all'
	>('all');
	const [formSearch, setFormSearch] = useState<string>('');
	const [formPlan, setFormPlan] = useState<'all' | 'free' | 'starter'>('all');
	const [formElement, setFormElement] = useState<string>('all');

	let formsToShow: NonNullable<
		GatsbyTypes.AllExamplesQuery['exampleForms']['contactForms']
	>['edges'][0][] = [];
	if (formCategory === 'all') {
		Object.keys(exampleForms).forEach(key => {
			formsToShow.push(
				...exampleForms[
					key as keyof GatsbyTypes.AllExamplesQuery['exampleForms']
				]!.edges!
			);
		});
		formsToShow.sort((a, b) => {
			return (
				new Date(b.node.created).getTime() - new Date(a.node.created).getTime()
			);
		});
	} else {
		formsToShow = [...exampleForms[formCategory]!.edges];
	}

	// filter by search
	if (formSearch !== '') {
		formsToShow = formsToShow.filter(({ node }) =>
			fuzzysearch(formSearch.toLowerCase(), node.name.toLowerCase())
		);
	}

	// filter by plan
	if (formPlan !== 'all') {
		formsToShow = formsToShow.filter(({ node }) => {
			if (formPlan === 'free') {
				return /^(free)(\s-\s)?/i.test(node.name);
			} else if (formPlan === 'starter') {
				return /^(starter)(\s-\s)?/i.test(node.name);
			}
			return true;
		});
	}

	// filter by element
	if (formElement !== 'all') {
		formsToShow = formsToShow.filter(({ node }) =>
			node.elementTypes.includes(formElement)
		);
	}

	const [previewForm, setPreviewForm] =
		useState<GatsbyTypes.formsFieldsFragment | null>(() => {
			if (typeof window !== 'undefined') {
				// try and get the hash
				const hash = window.location.hash;
				const match = hash.match(/^#wpeform-preview-form-id-(\d*)$/i);
				if (match && match[1]) {
					// find the form
					const matchedForm = formsToShow.find(
						fs => fs.node.id === match[1].toString()
					);
					if (matchedForm) {
						return matchedForm.node;
					}
				}
			}
			return null;
		});
	const [modalActive, setModalActive] = useState<boolean>(() => !!previewForm);

	useEffect(() => {
		if (previewForm && typeof window !== 'undefined') {
			window.location.hash = `wpeform-preview-form-id-${previewForm.id}`;
		}
	}, [previewForm]);

	const { controls, panels, themeStyle } = useMemo(() => {
		return parseStyleData(previewForm?.styleData ?? '');
	}, [previewForm]);

	const currentIndex = previewForm
		? formsToShow.findIndex(fs => fs.node.id === previewForm.id)
		: null;
	let nextForm: null | GatsbyTypes.formsFieldsFragment = null;
	let prevForm: null | GatsbyTypes.formsFieldsFragment = null;
	if (currentIndex !== -1 && currentIndex !== null) {
		if (formsToShow[currentIndex + 1]) {
			nextForm = formsToShow[currentIndex + 1]?.node;
		}
		if (currentIndex !== 0) {
			prevForm = formsToShow[currentIndex - 1]?.node;
		}
	}

	return (
		<>
			<Seo
				title="WordPress Form Builder Examples - WPEForm"
				description="Check various types of forms you can build with WPEForm WordPress Form Builder."
			/>
			<GeneralHero
				left={
					<ImageContainer>
						<StaticImage
							src="../images/forms-example.png"
							alt="Forms example"
							layout="fullWidth"
							breakpoints={[250, 400, 800]}
						/>
					</ImageContainer>
				}
				right={
					<GeneralHero.Article>
						<GeneralHero.Title>Example Forms</GeneralHero.Title>
						<GeneralHero.Subtitle>
							There's no need to start from scratch. We've done the hard work so
							that you can start modifying a form right away.
						</GeneralHero.Subtitle>
						<Cta.Group size="regular" align="center-left-desktop">
							<Cta type="primary" to="/pricing/" size="regular">
								Get Started
							</Cta>
							<Cta type="ghost" to="#example" size="regular">
								Browse Examples
							</Cta>
						</Cta.Group>
					</GeneralHero.Article>
				}
			/>
			<Constrain className="wide">
				<GeneralBody id="example">
					<Columns style={{ marginBottom: '30px' }}>
						<Column className="fourth">
							<SidebarHeading dense as="label" htmlFor="form-search">
								Search your form
							</SidebarHeading>
							<Text
								type="text"
								icon={faSearch}
								placeholder="eg: contact form"
								value={formSearch}
								onChange={e => {
									setFormSearch(e.target.value);
								}}
								name="form-search"
								id="form-search"
							/>
						</Column>
						<Column className="fourth">
							<SidebarHeading as="label" dense htmlFor="form-category">
								Filter by form category
							</SidebarHeading>
							<Select
								value={formCategory}
								onChange={e => {
									setFormCategory(e.target.value as any);
								}}
								name="form-category"
								id="form-category"
							>
								<option value="all">All Forms</option>
								{formCategoriesOrder.map(cat => (
									<option key={cat} value={cat}>
										{formCategories[cat]}
									</option>
								))}
							</Select>
						</Column>
						<Column className="fourth">
							<SidebarHeading as="label" dense htmlFor="form-plan">
								Filter by plan
							</SidebarHeading>
							<Select
								value={formPlan}
								onChange={e => {
									setFormPlan(e.target.value as any);
								}}
								name="form-category"
								id="form-category"
							>
								<option value="all">All Plans</option>
								<option value="free">Free Plan</option>
								<option value="starter">Starter Plan</option>
							</Select>
						</Column>
						<Column className="fourth">
							<SidebarHeading as="label" dense htmlFor="form-element">
								Filter by form element
							</SidebarHeading>
							<Select
								name="form-element"
								id="form-element"
								value={formElement}
								onChange={e => {
									setFormElement(e.target.value);
								}}
							>
								<option value="all">Show all elements</option>
								<optgroup label="CHOICE ELEMENTS">
									{sortedElements.choices.map(elm => (
										<option key={elm.type} value={elm.type}>
											{elm.title}
										</option>
									))}
								</optgroup>
								<optgroup label="INPUT ELEMENTS">
									{sortedElements.inputs.map(elm => (
										<option key={elm.type} value={elm.type}>
											{elm.title}
										</option>
									))}
								</optgroup>
								<optgroup label="DESIGN ELEMENTS">
									{sortedElements.designs.map(elm => (
										<option key={elm.type} value={elm.type}>
											{elm.title}
										</option>
									))}
								</optgroup>
								<optgroup label="SECURITY ELEMENTS">
									{sortedElements.securities.map(elm => (
										<option key={elm.type} value={elm.type}>
											{elm.title}
										</option>
									))}
								</optgroup>
							</Select>
						</Column>
					</Columns>
					<MainHeading dense>Browse template forms</MainHeading>
					{formsToShow.length ? (
						<Card.Grid>
							{formsToShow.map(({ node }) => (
								<Card
									key={node.id}
									layout="in-full-body"
									hasActiveState={false}
								>
									<Card.Title>
										{node.name.replace(
											/^(free|starter|professional|business)(\s-\s)?/i,
											''
										)}
									</Card.Title>
									<Card.Subtitle>
										Viewed {node.viewCount} time
										{node.viewCount === 1 ? '' : 's'}
									</Card.Subtitle>
									<Card.Section>
										<AvailableInTags
											hasTopGutter
											layout="left"
											neededPlan={
												node.name
													.match(
														/^(free|starter|professional|business)(\s-\s)?/i
													)?.[1]
													.toLowerCase() ?? 'free'
											}
											size="small"
										/>
									</Card.Section>
									<Card.Description secondary>
										Features the following form elements:{' '}
										{formatter.format(
											node.elementTypes
												.map(elmType => {
													const elmDef = formMeta.formMeta?.elements.find(
														elm => elm.type === elmType
													);
													if (elmDef) {
														return elmDef.definition.title;
													}
													return false;
												})
												.filter(Boolean)
										)}
										.<br />
										<br />
										<a
											href={node.standaloneLink}
											target="_blank"
											rel="noopener friend noreferrer"
										>
											View form landing page
										</a>
									</Card.Description>
									<Button.Group align="justify">
										<Button
											as="button"
											type="primary"
											onClick={() => {
												setPreviewForm(node);
												setModalActive(true);
											}}
										>
											Preview
										</Button>
										<Button
											as="link"
											type="ghost"
											href={`https://prod.wpeform.io/wp-eform/system/download-form/?formId=${node.id}`}
											rel="friend"
										>
											Download
										</Button>
									</Button.Group>
								</Card>
							))}
						</Card.Grid>
					) : (
						<p>No forms found. Try clearing your filters.</p>
					)}
				</GeneralBody>
				<GeneralBody>
					<Description className="has-gutter centered">
						More examples will be added soon. Stay tuned! 👍 Bdw, all forms{' '}
						rendered in this page are in{' '}
						<Link to="/docs/advanced-topics/headless-mode/">Headless mode</Link>
						. Do give it a look.
					</Description>
					<Cta.Group size="regular" align="center">
						<Cta type="default" to="/pricing/" size="regular">
							Start Using Now
						</Cta>
					</Cta.Group>
				</GeneralBody>
			</Constrain>
			<FooterCredit />
			<Modal
				isOpen={modalActive}
				setIsOpen={setModalActive}
				type="lightbox"
				header={previewForm?.name}
				onAfterClose={() => {
					setPreviewForm(null);
					if (typeof window !== 'undefined') {
						window.history.pushState(
							'',
							document.title,
							window.location.pathname + window.location.search
						);
					}
				}}
				contentKey={previewForm?.id}
				showResponsive
				footer={
					<>
						<Button
							as="button"
							type="primary"
							size="small"
							disabled={!prevForm}
							onClick={() => {
								setPreviewForm(prevForm);
							}}
							title={prevForm?.name}
							prefix={<FontAwesomeIcon icon={faAngleLeft} />}
						>
							PREVIOUS
						</Button>
						<Button
							as="button"
							type="primary"
							size="small"
							disabled={!nextForm}
							onClick={() => {
								setPreviewForm(nextForm);
							}}
							title={nextForm?.name}
							suffix={<FontAwesomeIcon icon={faAngleRight} />}
						>
							NEXT
						</Button>
						<Button
							as="link"
							type="ghost"
							href={`https://prod.wpeform.io/wp-eform/system/download-form/?formId=${previewForm?.id}`}
							size="small"
							rel="friend"
							title="Download This Form's export code"
						>
							<FontAwesomeIcon aria-label="Download Form" icon={faDownload} />
						</Button>
					</>
				}
			>
				{previewForm ? (
					<EmbedForm
						formId={previewForm.id}
						key={previewForm.id}
						themeStyle={themeStyle}
						controls={controls}
						panels={panels}
					/>
				) : null}
			</Modal>
		</>
	);
}

export const query = graphql`
	fragment formsFields on WPEForm_FormNodeType {
		id
		name
		standaloneLink
		elementTypes
		styleData
		viewCount
		created
	}

	query AllExamples {
		formMeta: wpeform {
			formMeta {
				elements {
					type
					definition {
						title
						category
					}
				}
			}
		}
		exampleForms: wpeform {
			featuresDemo: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "8", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			paymentForms: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "1", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			surveyForms: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "2", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			contactForms: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "3", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			quizForms: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "4", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			businessForms: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "5", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			educationForms: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "6", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
			eventsForm: forms(
				pagination: { with: OFFSET, orderby: "created", page: 1, first: 1000 }
				filter: { category: "7", resourceView: ALL }
			) {
				edges {
					node {
						...formsFields
					}
				}
			}
		}
	}
`;
